<template>
  <div class="bg-white h-100vh d-flex justify-content-center">
    <div class="shipment-card mt-0 main-container">
      <div class="header sticky-top">
        <div class="p-3 main-bg-color">
          <b-container class="p-0">
            <b-row no-gutters>
              <b-col md="12">
                <div class="text-right">
                  <b-button class="btn-language ml-2" @click="changeLanguage">
                    TH / EN
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="no-gutters align-items-center mt-3">
              <b-col class="province-container" cols="12" md="5">
                <v-select
                  class="style-chooser"
                  :options="getProvince"
                  label="address"
                  @search="onSearch"
                  @input="onChooseProvince"
                  v-model="startProvince"
                  :clearable="false"
                  :placeholder="$t('origin')"
                >
                  <template #option="{ address }">
                    <div class="custom-option">{{ address }}</div>
                  </template>
                </v-select>
              </b-col>
              <b-col class="text-center my-2 my-md-0" cols="12" md="2">
                <font-awesome-icon
                  class="mx-2 text-light media-transform"
                  icon="arrow-alt-circle-right"
                />
              </b-col>
              <b-col class="province-container" cols="12" md="5">
                <v-select
                  class="style-chooser"
                  :options="getProvince"
                  label="address"
                  @search="onSearch"
                  @input="onChooseProvince"
                  v-model="endProvince"
                  :clearable="false"
                  :placeholder="$t('destination')"
                >
                  <template #option="{ address }">
                    <div class="custom-option">{{ address }}</div>
                  </template>
                </v-select>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div v-if="startProvince && endProvince" class="bg-white p-3">
          <b-button-group
            class="custom-buttongroup w-100 rounded-0 wrap-button-shpping"
            size="sm"
          >
            <b-button
              v-for="(shipping, index) in shippingType"
              :key="index"
              :class="[
                shipping.kerryType == 2 && tabShippingSelected == index
                  ? 'kerry-cool'
                  : tabShippingSelected === index
                  ? 'button-active'
                  : 'button-noactive',
                'button-shpping rounded-0 mx-1 px-1 text-nowrap'
              ]"
              @click="onClickShppingTab(shipping, index)"
            >
              <img :src="boxWhite" alt="Kitten" class="logo-shipping-type" />
              <span class="text-white">
                {{ shipping.name }}
              </span>
            </b-button>
          </b-button-group>
        </div>
        <div
          v-if="startProvince && endProvince && tabShippingSelected == 0"
          class="bg-white p-3"
        >
          <b-button-group class="custom-buttongroup w-100" size="sm">
            <b-button
              :class="[tabIndex === 1 ? 'button-active' : 'button-noactive']"
              @click="onClickTab(1)"
              >{{ $t("box") }}</b-button
            >
            <b-button
              :class="[tabIndex === 2 ? 'button-active' : 'button-noactive']"
              @click="onClickTab(2)"
              >{{ $t("sealBag") }}</b-button
            >
            <b-button
              :class="[tabIndex === 3 ? 'button-active' : 'button-noactive']"
              @click="onClickTab(3)"
              >{{ $t("envelope") }}</b-button
            >
          </b-button-group>
        </div>
      </div>
      <div v-if="startProvince && endProvince">
        <!-- Kerry Express กล่อง -->
        <div v-if="tabIndex === 1 && tabShippingSelected == 0">
          <div class="bg-white px-4 py-1">
            <div class="mt-2 mb-3">
              <div
                class="d-flex justify-content-between align-items-center mb-5"
              >
                <div>
                  <span class="font-weight-bold f-size-16">{{
                    $t("dimension2")
                  }}</span>
                  <span class="f-size-12 text-color-border">
                    {{ `(${$t("max")} ${maxDimension} ${$t("cm")})` }}
                  </span>
                </div>
                <div @click="showModal" class="text-right f-size-12">
                  <font-awesome-icon
                    class="mr-1 text-color-border"
                    icon="info-circle"
                  />
                  <span class="text-tooltip"
                    >{{ $t("parcelSizeMeasure2") }}
                  </span>
                </div>
              </div>
              <vue-slider
                v-model.number="params.dimension"
                :min="0"
                :max="maxDimension"
                :interval="maxDimension > 10 ? 1 : 0.1"
                @change="onDraging"
                :process="process1"
                :tooltip="params.dimension ? 'always' : 'none'"
                dotSize="28"
                :tooltip-style="tooltipStyle"
                :process-style="processStyle"
                :marks="marks1"
                :lazy="true"
              >
                <template v-slot:dot>
                  <div class="dot-1 f-size-12">{{ $t("cm") }}</div>
                </template>
                <template v-slot:mark="{ pos, label }">
                  <div class="custom-mark" :style="{ left: `${pos}%` }">
                    {{ label }}
                  </div>
                </template>
              </vue-slider>
            </div>
            <div class="text-left mb-3">
              <div class="mb-5">
                <span class="font-weight-bold f-size-16">{{
                  $t("weight")
                }}</span>
                <span class="f-size-12 text-color-border">
                  {{ `(${$t("max")} ${maxWeight} ${$t("km")})` }}
                </span>
              </div>
              <vue-slider
                v-model.number="params.weight"
                :min="0"
                :max="maxWeight"
                :interval="maxWeight > 10 ? 1 : 0.1"
                @change="onDraging"
                :process="process2"
                :tooltip="params.weight ? 'always' : 'none'"
                dotSize="28"
                :tooltip-style="tooltipStyle"
                :process-style="processStyle"
                :marks="marks2"
                :lazy="true"
              >
                <template v-slot:dot>
                  <div class="dot-2 f-size-12">{{ $t("km") }}</div>
                </template>
                <template v-slot:mark="{ pos, label }">
                  <div class="custom-mark" :style="{ left: `${pos}%` }">
                    {{ label }}
                  </div>
                </template>
              </vue-slider>
            </div>
          </div>
          <div
            v-if="params.dimension && params.weight && tabIndex === 1"
            class="mt-3 px-3 text-left"
          >
            <p class="f-size-14 font-weight-bold">
              {{ $t("priceEstimation") }}
            </p>
            <div
              v-if="
                result.kerryExpress.box && result.kerryExpress.box.length > 0
              "
            >
              <PriceSection
                :dataListsSurCharge="boxSurChargeLists"
                :dataListsD2D="boxD2DLists"
                :lang="lang"
                :setShowMap="setShowMap"
                :handleClickPickupRequest="handleClickPickupRequest"
              />
              <LinkSection
                :setShowMap="setShowMap"
                :handleClickPickupRequest="handleClickPickupRequest"
              />
            </div>
            <div v-else>
              <div class="item-container main-color">{{ $t("noData") }}</div>
            </div>
          </div>
        </div>

        <!-- Kerry Express ซองพลาสติก -->
        <div
          v-else-if="tabIndex === 2 && tabShippingSelected == 0"
          class="mt-3 px-3 text-left"
        >
          <p class="f-size-14 font-weight-bold">
            {{ $t("priceEstimation") }}
          </p>
          <div
            v-if="
              result.kerryExpress.sealbag &&
              result.kerryExpress.sealbag.length > 0
            "
          >
            <PriceSection
              :dataListsSurCharge="sealbagSurChargeLists"
              :dataListsD2D="sealbagD2DLists"
              :lang="lang"
              :setShowMap="setShowMap"
              :handleClickPickupRequest="handleClickPickupRequest"
            />
            <LinkSection
              :tabShippingSelected="tabShippingSelected"
              :setShowMap="setShowMap"
              :handleClickPickupRequest="handleClickPickupRequest"
            />
          </div>
          <div v-else>
            <div class="item-container main-color">{{ $t("noData") }}</div>
          </div>
        </div>

        <!-- Kerry Express ซองเอกสาร -->
        <div
          v-else-if="tabIndex === 3 && tabShippingSelected == 0"
          class="mt-3 px-3 text-left"
        >
          <p class="f-size-14 font-weight-bold">
            {{ $t("priceEstimation") }}
          </p>
          <div
            v-if="
              result.kerryExpress.envelope &&
              result.kerryExpress.envelope.length > 0
            "
          >
            <PriceSection
              :dataListsSurCharge="envelopeSurChargeLists"
              :dataListsD2D="envelopeD2DLists"
              :lang="lang"
              :setShowMap="setShowMap"
              :handleClickPickupRequest="handleClickPickupRequest"
            />
            <LinkSection
              :tabShippingSelected="tabShippingSelected"
              :setShowMap="setShowMap"
              :handleClickPickupRequest="handleClickPickupRequest"
            />
          </div>
          <div v-else>
            <div class="item-container main-color">{{ $t("noData") }}</div>
          </div>
        </div>

        <!-- Kerry Cool -->
        <div
          v-else-if="
            result.isKerryCoolAvailable &&
            (tabShippingSelected == 1 || tabShippingSelected == 2)
          "
        >
          <div class="bg-white px-4 py-1">
            <div class="mt-2 mb-3">
              <div
                class="d-flex justify-content-between align-items-center mb-5"
              >
                <div>
                  <span class="font-weight-bold f-size-16">{{
                    $t("dimension")
                  }}</span>
                  <span class="f-size-12 text-color-border">
                    {{ `(${$t("max")} ${maxDimension} ${$t("cm")})` }}
                  </span>
                </div>
                <div @click="showModal" class="text-right f-size-12">
                  <font-awesome-icon
                    class="mr-1 text-color-border"
                    icon="info-circle"
                  />
                  <span class="text-tooltip"
                    >{{ $t("parcelSizeMeasure") }}
                  </span>
                </div>
              </div>
              <vue-slider
                v-model.number="params.dimension"
                :min="0"
                :max="maxDimension"
                :interval="maxDimension > 10 ? 1 : 0.1"
                @change="onDraging"
                :process="process1Cool"
                :tooltip="params.dimension ? 'always' : 'none'"
                dotSize="28"
                :tooltip-style="tooltipStyle"
                :process-style="processStyle"
                :marks="marks1"
                :lazy="true"
              >
                <template v-slot:dot>
                  <div class="dot-1 dot-1-cool f-size-12">{{ $t("cm") }}</div>
                </template>
                <template v-slot:mark="{ pos, label }">
                  <div class="custom-mark" :style="{ left: `${pos}%` }">
                    {{ label }}
                  </div>
                </template>
              </vue-slider>
            </div>
            <div class="text-left mb-3">
              <div class="mb-5">
                <span class="font-weight-bold f-size-16">{{
                  $t("weight")
                }}</span>
                <span class="f-size-12 text-color-border">
                  {{ `(${$t("max")} ${maxWeight} ${$t("km")})` }}
                </span>
              </div>
              <vue-slider
                v-model.number="params.weight"
                :min="0"
                :max="maxWeight"
                :interval="maxWeight > 10 ? 1 : 0.1"
                @change="onDraging"
                :process="process2"
                :tooltip="params.weight ? 'always' : 'none'"
                dotSize="28"
                :tooltip-style="tooltipStyle"
                :process-style="processStyle"
                :marks="marks2"
                :lazy="true"
              >
                <template v-slot:dot>
                  <div class="dot-2 f-size-12">{{ $t("km") }}</div>
                </template>
                <template v-slot:mark="{ pos, label }">
                  <div class="custom-mark" :style="{ left: `${pos}%` }">
                    {{ label }}
                  </div>
                </template>
              </vue-slider>
            </div>
          </div>
          <div
            v-if="
              params.dimension && params.weight && result.isKerryCoolAvailable
            "
            class="mt-3 px-3 text-left"
          >
            <p class="f-size-14 font-weight-bold">
              {{ $t("priceEstimation") }}
            </p>
            <div
              v-if="
                tabIndex == 4 &&
                tabShippingSelected == 1 &&
                result.kerryChilled.box &&
                result.kerryChilled.box.length > 0 &&
                params.dimension &&
                params.weight
              "
            >
              <PriceSection
                :dataListsSurCharge="kerryCoolChilledBoxSurChargeLists"
                :dataListsD2D="kerryCoolChilledBoxD2DLists"
                :lang="lang"
                :setShowMap="setShowMap"
                :handleClickPickupRequest="handleClickPickupRequest"
                isChilled
              />
              <LinkSection
                :setShowMap="setShowMap"
                :handleClickPickupRequest="handleClickPickupRequest"
                isKerryCool
              />
            </div>
            <div
              v-else-if="
                tabIndex == 4 &&
                tabShippingSelected == 2 &&
                result.kerryFrozen.box &&
                result.kerryFrozen.box.length > 0 &&
                params.dimension &&
                params.weight
              "
            >
              <PriceSection
                :dataListsSurCharge="kerryCoolFrozenBoxSurChargeLists"
                :dataListsD2D="kerryCoolFrozenBoxD2DLists"
                :lang="lang"
                :setShowMap="setShowMap"
                :handleClickPickupRequest="handleClickPickupRequest"
                isFrozen
              />
              <LinkSection
                :setShowMap="setShowMap"
                :handleClickPickupRequest="handleClickPickupRequest"
                isKerryCool
              />
            </div>
            <div v-else>
              <div class="item-container kec-color">{{ $t("noData") }}</div>
            </div>
          </div>
        </div>
        <div
          v-if="
            !result.isKerryCoolAvailable &&
            (tabShippingSelected == 1 || tabShippingSelected == 2)
          "
        >
          <div class="px-4 d-flex pt-3">
            <img
              src="@/assets/images/kerry-cool-box.png"
              alt="box-kerry-cool"
              class="box-img mx-auto w-50"
            />
          </div>
          <p class="f-size-16 mb-2 text-center text-muted mt-3">
            Sorry, Kerry Cool
          </p>
          <p class="f-size-14 text-center text-muted">
            is not available your area
          </p>
          <p class="f-size-14 text-center text-muted mt-2">
            We will be expending out service
          </p>
          <p class="f-size-14 text-center text-muted">So your area very soon</p>
        </div>
        <div v-else class="p-3 pb-4 text-left">
          <p class="f-size-14 font-weight-bold">{{ $t("remark2") }}</p>
          <div class="f-size-12 text-remark mt-2">
            <p>{{ `1. ${ tabShippingSelected == 0 ? $t("estimateDeliveryFee2") : $t("estimateDeliveryFeeCool")}` }}</p>
            <p>{{ `2. ${ tabShippingSelected == 0 ? $t("forDeliveryServiceToRemoteAreas2") : $t("forDeliveryServiceToRemoteAreasCool")}` }}</p>
            <p>{{ `3. ${ tabShippingSelected == 0 ? $t("forCashOnDelivery2") : $t("forCashOnDeliveryCool")}` }}</p>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="box-modal" ref="howtomeasure-modal" hide-header centered>
      <div class="text-center">
        <p class="f-size-16 font-weight-bold">
          {{ $t("parcelSizeMeasure") }}
        </p>
        <img src="@/assets/images/Boxes.svg" alt="box" class="my-4 box-img" />
        <p class="f-size-14 font-weight-bold mb-2">
          {{ $t("widthLengthHeightParcel") }}
        </p>
        <span class="f-size-14 mb-2">{{
          $t("priceEstimationCalculateFrom")
        }}</span>
      </div>
      <template #modal-footer>
        <b-button class="w-100 btn-main" @click="closeModal">{{
          $t("agree")
        }}</b-button>
      </template>
    </b-modal>
    <b-overlay :show="isLoading" no-wrap z-index="1030" :opacity="0.5">
    </b-overlay>
    <b-modal v-model="isShowMap" hide-header hide-footer centered>
      <FormMap
        :isShowMap="isShowMap"
        @setShowMap="setShowMap"
        @send-current-address="setCurrentLocation"
      />
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import PriceSection from "./component/PriceSection.vue";
import OtherLoading from "@/components/other/OtherLoading";
import FormMap from "@/components/service-location/FormMap.vue";
import LinkSection from "./component/LinkSection.vue";
import boxWhite from "@/assets/images/kerry_icon_white.png";
import boxBlack from "@/assets/images/kerry_icon_black.png";
export default {
  name: "ShowPrice",
  components: {
    PriceSection,
    OtherLoading,
    FormMap,
    LinkSection
  },
  beforeCreate: async function () {
    if (this.$liff.isInClient()) {
      this.$liff
        .init({ liffId: this.$liff_Main_ID })
        .then(() => {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
          });
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  },
  data() {
    return {
      boxWhite: boxWhite,
      boxBlack: boxBlack,
      lang: "",
      tabIndex: 1,
      isLoading: false,
      maxWeight: 0,
      maxDimension: 0,
      startProvince: null,
      endProvince: null,
      size: 0,
      weight: 0,
      provinces: [],
      provinceFiltered: [],
      marks1: [],
      marks2: [],
      process1: dotsPos => [[0, dotsPos[0], { backgroundColor: "#f47559" }]],
      process1Cool: dotsPos => [
        [0, dotsPos[0], { backgroundColor: "#0097DD" }]
      ],
      process2: dotsPos => [[0, dotsPos[0], { backgroundColor: "#000000" }]],
      tooltipStyle: {
        color: "black",
        backgroundColor: "white",
        border: "1px solid #bebebe",
        padding: "4px 8px"
      },
      processStyle: {
        backgroundColor: "#EBEFF0 !important"
      },
      result: {},
      params: {
        origin: {
          id: 0,
          postalCode: "",
          province: ""
        },
        destination: {
          id: 0,
          postalCode: "",
          province: ""
        },
        dimension: 0,
        weight: 0
      },
      tabShippingSelected: 0,
      shippingSelected: {
        name: "",
        kerryType: 0,
        subType: 0,
        iconUrl: ""
      },
      shippingType: [],
      isShowMap: false
    };
  },
  async created() {
    const lang = this.$route.params.lang ? this.$route.params.lang : "th";
    this.lang = lang;
    await this.getAddress();
    this.getShippingType();
  },
  watch: {
    "$route.params.lang": function (newVal, oldVal) {
      this.lang = newVal;
    }
  },
  methods: {
    async getAddress() {
      const url = `${this.$baseUrl}/kerryApi/PostalCode`;
      const search = {
        search: "",
        language:
          this.$route.params.lang && this.$route.params.lang == "en"
            ? "EN"
            : "TH"
      };
      try {
        this.isLoading = true;
        const res = await axios.post(url, search);

        const data = res.data;
        if (data.result === 1) {
          this.isLoading = false;
          this.provinces = data.detail;
        } else {
          this.isLoading = false;
          this.provinces = [];
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        alert(error.message);
      }
    },
    changeLanguage() {
      let getLanguage = $cookies.get("language");
      getLanguage = getLanguage == "th" ? "en" : "th";

      let finalUrl = `/show-price/${getLanguage}/${decodeURIComponent(
        window.location.search
      )}`;
      this.$router
        .replace(finalUrl)
        // .then(() => {
        //   this.getTopicList();
        // })
        .catch(() => {
          return;
        });
    },
    async onDraging() {
      await this.estimatingPrice();
    },
    onClickTab(idx) {
      this.tabIndex = idx;
    },
    onSearch(value) {
      this.provinceFiltered = this.provinces.filter(e =>
        e.address.includes(value)
      );
    },
    showModal() {
      this.$refs["howtomeasure-modal"].show();
    },
    closeModal() {
      this.$refs["howtomeasure-modal"].hide();
    },
    clearDup(arr) {
      const keys = ["address"],
        filtered = arr.filter(
          (
            s => o =>
              (k => !s.has(k) && s.add(k))(keys.map(k => o[k]).join("|"))
          )(new Set())
        );
      return filtered;
    },
    async estimatingPrice() {
      this.isLoading = true;
      const url = `${this.$baseUrl}/kerryapi/EstimatePrice`;
      try {
        const res = await axios.post(url, this.params);
        const data = res.data;
        if (data.result === 1) {
          this.result = data.detail;
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async onChooseProvince() {
      if (this.startProvince && this.endProvince) {
        this.params.origin.id = this.startProvince.id;
        this.params.origin.postalCode = this.startProvince.zipCode;
        this.params.origin.province = this.startProvince.province;
        this.params.destination.id = this.endProvince.id;
        this.params.destination.postalCode = this.endProvince.zipCode;
        this.params.destination.province = this.endProvince.province;
        await this.estimatingPrice();
        const maxWeight = 30;
        const maxDimension = 280;
        this.maxWeight = maxWeight;
        this.maxDimension = maxDimension;
        this.marks1 = [0, maxDimension];
        this.marks2 = [0, maxWeight];
      }
    },
    filterD2DLists(arr) {
      const packageD2D = arr.filter(e => {
        return e.packageD2DPrice > 0;
      });
      return packageD2D;
    },
    filterSurChargeList(arr) {
      const packageSurCharge = arr.filter(e => {
        return e.packageSurcharge > 0;
      });
      return packageSurCharge;
    },
    filterSurChargeChilledList(arr) {
      const packageSurCharge = arr.filter(e => {
        return e.packageSurchargeChilled > 0;
      });
      return packageSurCharge;
    },
    filterSurChargeFrozenList(arr) {
      const packageSurCharge = arr.filter(e => {
        return e.packageSurchargeFrozen > 0;
      });
      return packageSurCharge;
    },
    async getShippingType() {
      this.isLoading = true;
      const url = `${this.$baseUrl}/message/kerrytype`;
      try {
        const res = await axios.get(url);
        const data = res.data;
        if (data.result === 1) {
          this.shippingType = data.detail;
          this.onClickShppingTab(this.shippingType[0], 0);
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    onClickShppingTab(val, idx) {
      this.tabShippingSelected = idx;
      this.shippingSelected = val;
      this.tabIndex = idx == 0 ? 1 : 4;
    },
    setShowMap(show) {
      this.isShowMap = show;
    },
    setCurrentLocation(val) {
      this.pushLocation(val.latitude, val.longitude);
    },
    pushLocation: async function (lat, lng) {
      let body = {
        sessionId: this.$route.query.sessionId,
        latitude: lat,
        longitude: lng,
        address: "",
        kerryType: this.shippingSelected.kerryType, // KEX=1 , KEC=2
        subType: this.shippingSelected.subType // KerryCoolType : Chiled = 1, Frozen =2, None = 0
      };
      this.isLoading = true;
      const url = `${this.$baseUrl}/message/pushLocation`;
      try {
        const res = await axios.post(url, body);
        this.isLoading = false;
        const data = res.data;
        if (data.result === 1) {
          window.close();
        } else {
          alert(data.message);
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async handleClickPickupRequest(isKerryCool) {
      if (!this.$route.query.sessionId) alert("Session id not found");
      this.isLoading = true;
      const url = `${this.$baseUrl}/kerryapi/ReplyPickup`;
      try {
        const res = await axios.post(url, {
          sessionId: this.$route.query.sessionId,
          kerryType: isKerryCool ? "KEC" : "KEX"
        });
        const data = res.data;
        if (data.result === 1) {
          window.close();
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    }
  },
  computed: {
    envelopeD2DLists() {
      return this.result.kerryExpress.envelope
        ? this.filterD2DLists(this.result.kerryExpress.envelope)
        : [];
    },
    envelopeSurChargeLists() {
      return this.result.kerryExpress.envelope
        ? this.filterSurChargeList(this.result.kerryExpress.envelope)
        : [];
    },
    boxD2DLists() {
      return this.result.kerryExpress.box
        ? this.filterD2DLists(this.result.kerryExpress.box)
        : [];
    },
    boxSurChargeLists() {
      return this.result.kerryExpress.box
        ? this.filterSurChargeList(this.result.kerryExpress.box)
        : [];
    },
    sealbagD2DLists() {
      return this.result.kerryExpress.sealbag
        ? this.filterD2DLists(this.result.kerryExpress.sealbag)
        : [];
    },
    sealbagSurChargeLists() {
      return this.result.kerryExpress.sealbag
        ? this.filterSurChargeList(this.result.kerryExpress.sealbag)
        : [];
    },
    kerryCoolChilledBoxD2DLists() {
      return this.result.kerryChilled.box
        ? this.filterD2DLists(this.result.kerryChilled.box)
        : [];
    },
    kerryCoolChilledBoxSurChargeLists() {
      return this.result.kerryChilled.box
        ? this.filterSurChargeChilledList(this.result.kerryChilled.box)
        : [];
    },
    kerryCoolFrozenBoxD2DLists() {
      return this.result.kerryFrozen.box
        ? this.filterD2DLists(this.result.kerryFrozen.box)
        : [];
    },
    kerryCoolFrozenBoxSurChargeLists() {
      return this.result.kerryFrozen.box
        ? this.filterSurChargeFrozenList(this.result.kerryFrozen.box)
        : [];
    },
    getProvince() {
      if (this.provinceFiltered.length > 0) {
        return this.clearDup(this.provinceFiltered.slice(0, 50));
      } else {
        return this.clearDup(this.provinces.slice(0, 50));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-container {
  background-color: #e0e0e0;
}

.custom-mark {
  position: absolute;
  bottom: 24px;
  transform: translateX(-50%);
  white-space: nowrap;
  color: #cccccc;
}

.text-remark {
  color: #646366;
}

.text-color-border {
  color: #cccccc;
}

.text-tooltip {
  color: #cccccc;
  text-decoration: underline;
  cursor: pointer;
}

.dot-1 {
  background-color: #ffffff;
  border: 3px solid #f47559;
  border-radius: 50%;
  box-shadow: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #f47559;
}

.dot-1-cool {
  border: 3px solid #0097dd !important;
  color: #0097dd !important;
}

.item-container {
  background-color: white;
  padding: 1rem;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  align-items: center;
  border-radius: 8px;
}

.dot-2 {
  background-color: #ffffff;
  border: 3px solid #000000;
  border-radius: 50%;
  box-shadow: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #000000;
}

.modal-content .modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  background-color: #f37021 !important;
}

.province-container {
  background-color: white;
  border-radius: 4px;
  align-items: center;
  font-size: 14px;
}

.custom-option {
  font-size: 14px;
  white-space: normal;
  vertical-align: top;
  display: inline-block;
  line-break: loose;
  margin: 0.5rem 0;
  overflow-wrap: anywhere;
}
.btn-language {
  background: white !important;
  color: #f37021 !important;
  border: none;
}

.box-img {
  width: 100%;
  height: 100%;
}

p {
  margin-bottom: 0;
}

h4 {
  color: #f37021;
}
.logo-shipping-type {
  width: 2rem;
  height: auto;
}

.button-shpping {
  border-radius: 0.5rem !important;
  border-color: none;
  border: none !important;
}
.wrap-button-shpping {
  overflow-x: auto;
}
.wrap-button-shpping,
.wrap-button-shpping .btn:active {
  background-color: inherit !important;
  border-radius: 0.5rem !important;
  border: none !important;
}
.wrap-button-shpping .btn:first-child {
  margin-left: 0px !important;
  border-radius: 0.5rem !important;
}
.wrap-button-shpping .btn:last-child {
  margin-right: 0px !important;
  border-radius: 0.5rem !important;
}

.wrap-button-shpping .kerry-cool {
  background-color: #0097dd !important;
}

.cursor-pointer {
  cursor: pointer;
}

::v-deep .modal-map-bar {
  padding: 0rem;
}
@media only screen and (max-width: 760px) {
  .media-transform {
    transform: rotate(90deg);
  }
}
</style>
