<template>
  <div class="mt-3 pt-2 text-left">
    <p class="f-size-14 font-weight-bold pb-1">
      {{ $t("additionalService") }}
    </p>
    <div>
      <div
        :class="[
          'd-flex text-white rounded-pill px-3 py-2 f-size-12 mb-2 cursor-pointer',
          !isKerryCool ? 'main-bg-color' : 'bg-kec'
        ]"
        @click="setShowMap(true)"
      >
        <font-awesome-icon class="mr-2 my-auto" icon="map-marker-alt" />
        {{ isKerryCool? $t("kerryCoolServiceLocation") : $t("kerryExpressServiceLocation") }}
        <font-awesome-icon class="ml-auto my-auto" icon="chevron-right" />
      </div>
      <div
        :class="[
          'd-flex main-bg-color text-white rounded-pill px-3 py-2 f-size-12 cursor-pointer',
          !isKerryCool ? 'main-bg-color' : 'bg-kec'
        ]"
        @click="handleClickPickupRequest(isKerryCool)"
      >
        <font-awesome-icon class="mr-2 my-auto" icon="truck" />
        {{ $t("pickupRequestFee") }}
        <font-awesome-icon class="ml-auto my-auto" icon="chevron-right" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceSection",
  props: {
    isKerryCool: {
      type: Boolean,
      required: false
    },
    setShowMap: {
      type: Function,
      required: false
    },
    handleClickPickupRequest: {
      type: Function,
      required: false
    }
  }
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 374px) {
}
</style>
