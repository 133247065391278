var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-map"},[_c('div',{staticClass:"modal-map-bar"},[_c('span',{staticClass:"title-map mb-2"},[_vm._v(_vm._s(_vm.$t("shareLocation")))]),_c('font-awesome-icon',{staticClass:"close-btn",attrs:{"id":"mapClose","icon":"times"},on:{"click":_vm.hideMap}})],1),(_vm.showMap)?_c('div',{staticClass:"map"},[_c('div',{staticClass:"input-location-name"},[_c('font-awesome-icon',{staticClass:"icon-search",style:({ color: _vm.primaryColor }),attrs:{"icon":"search"}}),_c('GmapAutocomplete',{staticClass:"custom-input",attrs:{"position.sync":"{ lat: myCoordinates.lat, lng: myCoordinates.lng }","placeholder":"ค้นหาจุดที่ตั้งของคุณ"},on:{"place_changed":_vm.setPlace}})],1),_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"250px"},attrs:{"center":{ lat: _vm.myCoordinates.lat, lng: _vm.myCoordinates.lng },"zoom":_vm.zoom,"options":{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      },"map-type-id":"terrain"},on:{"dragend":_vm.handleDrag}}),_c('div',{staticClass:"location-pin",style:({
        backgroundImage:
          'url(' + require('@/assets/images/current-location.svg') + ')',
      })}),_c('div',{staticClass:"container-btn"},[_c('button',{staticClass:"btn-send",style:({ backgroundColor: _vm.primaryColor }),attrs:{"id":"btnModalChat"},on:{"click":function($event){return _vm.sendMessageLocation()}}},[_c('span',[_vm._v(_vm._s(_vm.$t("send")))])])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }