var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataListsSurCharge.length > 0)?_c('div',{staticClass:"mt-3"},[_c('p',{class:[
        'font-weight-bold f-size-14 main-color',
        _vm.isChilled || _vm.isFrozen ? 'd-none text-kerry-cool' : ''
      ]},[_vm._v(" "+_vm._s(_vm.$t("parcelShopFee"))+" ")]),_vm._l((_vm.dataListsSurCharge),function(item,index){return _c('div',{key:index,staticClass:"item-container"},[_c('div',{staticClass:"d-flex detail-container"},[_c('div',{staticClass:"mr-3"},[_c('div',{staticClass:"logo-image-container text-center"},[_c('img',{staticClass:"logo-img",attrs:{"src":item.packageImageUrl}})])]),_c('div',{staticClass:"d-flex justify-content-center flex-column mr-3"},[_c('p',{staticClass:"f-size-14 font-weight-bold mb-2"},[_vm._v(" "+_vm._s(_vm.lang === "th" ? item.packageName.th : item.packageName.en)+" ")]),_c('div',{class:[_vm.lang == 'th' ? 'text-nowrap' : '', 'describe-container']},[_vm._v(" "+_vm._s(_vm.lang === "th" ? item.packageDetail.th : item.packageDetail.en)+" ")])])]),_c('div',{staticClass:"price-container"},[_c('h4',{class:[
            'text-right',
            _vm.isChilled || _vm.isFrozen ? 'text-kerry-cool' : ''
          ]},[_vm._v(" "+_vm._s(_vm.isChilled ? item.packageSurchargeChilled : _vm.isFrozen ? item.packageSurchargeFrozen : item.packageSurcharge)+" ")]),_c('p',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t("bath")))])])])})],2):_vm._e(),(_vm.dataListsD2D.length > 0)?_c('div',{staticClass:"mt-3"},[_c('p',{staticClass:"font-weight-bold f-size-14 main-color"},[_vm._v(" "+_vm._s(_vm.$t("pickupRequestFee"))+" ")]),_vm._l((_vm.dataListsD2D),function(item,index){return _c('div',{key:index,staticClass:"item-container"},[_c('div',{staticClass:"d-flex detail-container"},[_c('div',{staticClass:"mr-3"},[_c('div',{staticClass:"logo-image-container text-center"},[_c('img',{staticClass:"logo-img",attrs:{"src":item.packageImageUrl}})])]),_c('div',{staticClass:"d-flex justify-content-center flex-column mr-3"},[_c('p',{staticClass:"f-size-14 font-weight-bold mb-2"},[_vm._v(" "+_vm._s(_vm.lang === "th" ? item.packageName.th : item.packageName.en)+" ")]),_c('div',{class:[_vm.lang == 'th' ? 'text-nowrap' : '', 'describe-container']},[_vm._v(" "+_vm._s(_vm.lang === "th" ? item.packageDetail.th : item.packageDetail.en)+" ")])])]),_c('div',{staticClass:"price-container"},[_c('h4',{staticClass:"text-right"},[_vm._v(_vm._s(item.packageD2DPrice))]),_c('p',{class:[
            'text-right',
            _vm.isChilled || _vm.isFrozen ? 'text-kerry-cool' : ''
          ]},[_vm._v(" "+_vm._s(_vm.$t("bath"))+" ")])])])})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }